import { Box, Typography } from "@mui/material";
import Logo from "../assets/icons/Logo";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  color: string;
  left?: string;
  right?: string;
  logoSize: string;
  fontSize: string;
}

function LogoName({ color, left, right, logoSize, fontSize }: Props) {
  return (
    <RouterLink to="/">
      <Box display="flex" alignItems="center">
        <Logo sx={{ fill: color, width: logoSize, height: logoSize }} />
        <Typography
          fontFamily="Inter"
          fontSize={fontSize}
          fontWeight={700}
          ml="0.5rem"
          sx={{ background: `linear-gradient(90deg, ${left} 0%, ${right} 100%)`, WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}
        >
          LSPDD
        </Typography>
      </Box>
    </RouterLink>
  );
}

export default LogoName;
