import { LightbulbOutlined, PersonOutlineOutlined, SpaceDashboardOutlined, Notes, DescriptionOutlined, ShowChart, TipsAndUpdatesOutlined } from "@mui/icons-material";
import { PATH_DASHBOARD } from "../../../routes/paths";

const navConfig = [
  {
    subheader: "dashboard.general",
    items: [
      {
        title: "dashboard.title",
        path: PATH_DASHBOARD.general.root,
        icon: <SpaceDashboardOutlined />,
      },
      {
        title: "dashboard.archive",
        path: PATH_DASHBOARD.general.archive,
        icon: <DescriptionOutlined />,
      },
    ],
  },
  {
    subheader: "dashboard.management",
    role: "Admin",
    items: [
      {
        title: "dashboard.users",
        path: PATH_DASHBOARD.user.root,
        icon: <PersonOutlineOutlined />,
      },
      {
        title: "dashboard.models",
        path: PATH_DASHBOARD.model.root,
        icon: <TipsAndUpdatesOutlined />,
      },
      {
        title: "dashboard.lamps",
        path: PATH_DASHBOARD.lamp.root,
        icon: <LightbulbOutlined />,
      },
      {
        title: "dashboard.captures",
        path: PATH_DASHBOARD.capture.root,
        icon: <ShowChart />,
      },
      {
        title: "dashboard.content",
        path: PATH_DASHBOARD.content.root,
        icon: <Notes />,
      },
    ],
  },
];

export default navConfig;
