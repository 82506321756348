import i18n from "../locales/i18n";

export function getNamesByLanguage(data, language) {
  return language === "fr"
    ? data.map((item) => item?.translation?.fr?.short || item?.translation?.fr?.long || item.name)
    : data.map((item) => item?.translation?.en?.short || item?.translation?.en?.long || item.name);
}

export function getNameByLanguage(data, language) {
  return language === "fr" ? data?.translation?.fr : data?.translation?.en;
}

export const getContentByName = (content, name) => {
  return content.find((item: any) => item.name === name && item.language === i18n.language);
};
