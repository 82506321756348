import { NavLink as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Link, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const LinkStyle: any = styled(Link)(({ theme }) => ({
  ...theme.typography.body1,
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(4),
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": {
    opacity: 0.48,
  },
}));

const navConfig: any = [
  { title: "database", path: "/database" },
  { title: "about", path: "/about-us" },
  { title: "contact_us", path: "/contact-us" },
];

interface MenuProps {
  isOffset: boolean;
  isDark: boolean;
}

export default function MenuDesktop({ isOffset, isDark }: MenuProps) {
  return (
    <Stack direction="row">
      {navConfig.map((link) => (
        <MenuDesktopItem key={link.title} item={link} isOffset={isOffset} isDark={isDark} />
      ))}
    </Stack>
  );
}

interface ItemProps {
  item: {
    path: string;
    title: string;
  };
  isDark: boolean;
  isOffset: boolean;
}

function MenuDesktopItem({ item, isDark, isOffset }: ItemProps) {
  const { title, path } = item;
  const { t } = useTranslation();

  return (
    <LinkStyle
      component={RouterLink}
      to={path}
      end={path === "/"}
      sx={{
        ...(isDark && { color: "common.white" }),
        ...(isOffset && { color: "text.primary" }),
        "&.active": {
          color: "primary.main",
        },
      }}
    >
      {t(title)}
    </LinkStyle>
  );
}
