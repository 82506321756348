import { Suspense, lazy } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import MainLayout from "../layouts/main/MainLayout";
import SimpleLayout from "../layouts/SimpleLayout";
import LoadingScreen from "../components/LoadingScreen";
import AuthGuard from "../guards/AuthGuard";
import DashboardLayout from "../layouts/dashboard/DashboardLayout";
import GuestGuard from "../guards/GuestGuard";
import { PATH_AFTER_LOGIN } from "../config";
import RoleBasedGuard from "../guards/RoleBasedGuard";

const Loadable = (Component: any) => (props: JSX.IntrinsicAttributes) => {
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}>
      <Component {...props} />
    </Suspense>
  );
};

const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Home = Loadable(lazy(() => import("../pages/home/Home")));
const Database = Loadable(lazy(() => import("../pages/database/Database")));
const About = Loadable(lazy(() => import("../pages/about/About")));
const Contact = Loadable(lazy(() => import("../pages/contact/Contact")));
const Lamp = Loadable(lazy(() => import("../pages/lamp/Lamp")));
const Comparator = Loadable(lazy(() => import("../pages/comparator/Comparator")));
const NotFound = Loadable(lazy(() => import("../pages/not-found/NotFound")));
const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));
const Archive = Loadable(lazy(() => import("../pages/dashboard/archive/Archive")));
const UserList = Loadable(lazy(() => import("../pages/dashboard/user/UserList")));
const ContentList = Loadable(lazy(() => import("../pages/dashboard/content/ContentList")));
const ContentEdit = Loadable(lazy(() => import("../pages/dashboard/content/ContentEdit")));
const ModelLayout = Loadable(lazy(() => import("../pages/dashboard/model/ModelLayout")));
const ModelEdit = Loadable(lazy(() => import("../pages/dashboard/model/ModelEdit")));
const LampList = Loadable(lazy(() => import("../pages/dashboard/lamp/LampList")));
const LampEdit = Loadable(lazy(() => import("../pages/dashboard/lamp/LampEdit")));
const CaptureList = Loadable(lazy(() => import("../pages/dashboard/capture/CaptureList")));

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "general", element: <Dashboard /> },
        { path: "archive", element: <Archive /> },
        {
          path: "user",
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles="Admin">
                  <UserList />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: "model",
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles="Admin">
                  <ModelLayout />
                </RoleBasedGuard>
              ),
              index: true,
            },
            { path: ":id/edit", element: <ModelEdit /> },
          ],
        },
        {
          path: "lamp",
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles="Admin">
                  <LampList />
                </RoleBasedGuard>
              ),
              index: true,
            },
            { path: ":id/edit", element: <LampEdit /> },
          ],
        },
        {
          path: "capture",
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles="Admin">
                  <CaptureList />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: "content",
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles="Admin">
                  <ContentList />
                </RoleBasedGuard>
              ),
              index: true,
            },
            { path: ":name/edit", element: <ContentEdit /> },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <SimpleLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <Home />, index: true },
        { path: "/database", element: <Database /> },
        { path: "/database/:id", element: <Lamp /> },
        { path: "/comparator/:id1?/:id2?", element: <Comparator /> },
        { path: "/about-us", element: <About /> },
        { path: "/contact-us", element: <Contact /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
