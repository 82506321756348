import { Box, Divider, Grid, Link, Stack, Typography, styled } from "@mui/material";
import { Container } from "@mui/system";
import { useTranslation } from "react-i18next";
import Colors from "../../styles/Colors";
import LogoName from "../../components/LogoName";
import ShelterBayCloud from "../../assets/images/sbcloud.png";
import Image from "../../components/Image";
import { HashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import moment from "moment";
import i18n from "../../locales/i18n";
import { getContentByName } from "../../utils/function";
import axios from "../../utils/axios";
import { extractLinks } from "../../pages/dashboard/content/DialogPreview";

const RootStyle = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.palette.background.default,
}));

function MainFooter() {
  const { t } = useTranslation();
  const [lastLamp, setLastLamp]: any = useState(null);
  const [content, setContent]: any = useState(null);

  useEffect(() => {
    axios.get("/content/tag/other").then((res) => setContent(res.data));
    axios.get("/lamp/lastCreated").then((response) => setLastLamp(response.data));
  }, []);

  const LINKS = [
    {
      headline: t("links"),
      children: [
        { name: t("database"), href: "/database" },
        { name: t("about"), href: "/about-us" },
        { name: t("contact_us"), href: "/contact-us" },
        { name: t("sign_in"), href: "/auth/login" },
        { name: t("terms"), href: "/about-us#terms" },
      ],
    },
    {
      headline: t("developers"),
      children: [{ name: "Antoine Morin-Paulhus" }, { name: "William Beauchesne" }, { name: "Louis-Olivier Cyr" }, { name: "Claudine Laflèche" }, { name: "Alexandre Fortier" }],
    },
    {
      headline: t("project_team"),
      children: [
        { name: "Philippe Boudreau" },
        { name: "Karine Desrosiers" },
        { name: "Gabriel Leblanc-Fontaine" },
        { name: "Laurent Mailloux-Bourassa" },
        { name: "Miguel Ouellette" },
        { name: "Félix-Antoine Simard" },
      ],
    },
  ];

  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid container justifyContent={{ xs: "center", md: "space-between" }} sx={{ textAlign: { xs: "center", md: "left" } }}>
          <Grid item xs={12} sx={{ mb: 3 }}>
            <Box display="flex" justifyContent={{ xs: "center", md: "left" }}>
              <LogoName color={Colors.blueJeans} left={Colors.blueJeans} right={Colors.ceruleanBlue} logoSize="1.3em" fontSize="1.5rem" />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: 3 }}>
            {content && (
              <Typography gutterBottom variant="body2" sx={{ pr: { md: 5 } }}>
                {extractLinks(getContentByName(content, "footer").content)}
              </Typography>
            )}

            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {lastLamp && t("last_lamp", { date: i18n.language === "fr" ? moment(lastLamp).locale("fr").format("Do MMMM YYYY") : moment(lastLamp).locale("en").format("MMMM Do YYYY") })}
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: "column", md: "row" }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={1}>
                  <Typography component="p" variant="subtitle2" textTransform="uppercase">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <Typography smooth to={link.href} key={link.name} color="inherit" variant="body2" component={HashLink}>
                      {link.name}
                    </Typography>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Stack direction={{ xs: "column", md: "row" }} alignItems={{ xs: "center", md: "flex-end" }} justifyContent="space-between" mt={8} pb={5}>
          <Typography variant="body2" fontSize={13} textAlign={{ xs: "center", md: "left" }}>
            {`Copyright © ${new Date().getFullYear()} LSPDD ${t("copyright")}`}
          </Typography>

          <Stack direction="row" alignItems="flex-end" spacing={0.5}>
            <Typography variant="body2" fontSize={13} textAlign={{ xs: "center", md: "left" }}>
              {t("powered_by")}
            </Typography>
            <Link href="https://shelterbaycloud.ca" target="_blank" rel="noopener noreferrer">
              <Image src={ShelterBayCloud} sx={{ width: 120 }} />
            </Link>
          </Stack>
        </Stack>
      </Container>
    </RootStyle>
  );
}

export default MainFooter;
