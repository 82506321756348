import { Dialog, DialogContent, DialogTitle, Link, Typography } from "@mui/material";
import Scrollbar from "../../../components/Scrollbar";

interface Props {
  content: string;
  onClose: any;
  open: boolean;
}

export function extractLinks(text: string) {
  const parts = text.split(/(\[.*?\]\(.*?\)|\n)/);

  return parts.map((part, index) => {
    const match = part.match(/\[(.*?)\]\((.*?)\)/);

    if (match) {
      const linkText = match[1];
      const linkUrl = match[2];

      return (
        <Link key={index} href={linkUrl} target="_blank" rel="noopener noreferrer">
          {linkText}
        </Link>
      );
    } else if (part === "\n") {
      // Handle newline
      return <br key={index} />;
    } else {
      // Text outside of links and newline
      return <span key={index}>{part}</span>;
    }
  });
}

export default function DialogPreview({ open, onClose, content }: Props) {
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogTitle sx={{ pt: 2, pb: 1 }}>Aperçu</DialogTitle>

      <DialogContent>
        <Scrollbar>
          <Typography>{extractLinks(content)}</Typography>
        </Scrollbar>
      </DialogContent>
    </Dialog>
  );
}
