import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Button, AppBar, Toolbar, Container, Stack } from "@mui/material";
import useOffSetTop from "../../hooks/useOffSetTop";
import LogoName from "../../components/LogoName";
import MenuDesktop from "./MenuDesktop";
import Colors from "../../styles/Colors";
import LanguagePopover from "../../components/LanguagePopover";
import { useTranslation } from "react-i18next";
// import SettingMode from "../../components/settings/SettingMode";
import AccountPopover from "../../components/AccountPopover";
import useAuth from "../../hooks/useAuth";
import { HEADER } from "../../config";
import useResponsive from "../../hooks/useResponsive";
import MenuMobile from "./MenuMobile";

// To add the theme mode button, uncomment the following line: 10 and 76

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(["height", "background-color"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("md")]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled("div")(({ theme }: any) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: "auto",
  borderRadius: "50%",
  position: "absolute",
  width: "calc(100% - 48px)",
  boxShadow: theme.customShadows.z8,
}));

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT - 50);
  const { pathname } = useLocation();
  const isDark = pathname === "/" || pathname === "/about-us" || pathname === "/database" || pathname === "/contact-us";
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const isDesktop = useResponsive("up", "md");

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: "transparent" }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: "background.default",
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LogoName color={Colors.blueJeans} left={Colors.blueJeans} right={Colors.ceruleanBlue} logoSize="1.3em" fontSize="1.5rem" />

          {isDesktop && <MenuDesktop isOffset={isOffset} isDark={isDark} />}

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={1.5}>
            {/* <SettingMode /> */}
            <LanguagePopover />
            {isAuthenticated ? (
              <AccountPopover />
            ) : (
              <Button variant="contained" href="/auth/login">
                {t("login")}
              </Button>
            )}
          </Stack>

          {!isDesktop && <MenuMobile isOffset={isOffset} isDark={isDark} />}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
