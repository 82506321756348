import { NavLink as RouterLink } from "react-router-dom";
import { Box, ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import { ExpandMore, NavigateNext } from "@mui/icons-material";
import { alpha, styled } from "@mui/material/styles";
import { ICON, NAVBAR } from "../../../config";
import { useTranslation } from "react-i18next";

export const ListItemStyle: any = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== "activeRoot" && prop !== "activeSub" && prop !== "subItem",
})(({ activeRoot, activeSub, subItem, theme }: any) => ({
  ...theme.typography.body2,
  position: "relative",
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  // activeRoot
  ...(activeRoot && {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
  }),
  // subItem
  ...(subItem && {
    height: NAVBAR.DASHBOARD_ITEM_SUB_HEIGHT,
  }),
}));

export const ListItemTextStyle: any = styled(ListItemText)(({ theme }: any) => ({
  whiteSpace: "nowrap",
  transition: theme.transitions.create(["width", "opacity"], {
    duration: theme.transitions.duration.shorter,
  }),
}));

export const ListItemIconStyle = styled(ListItemIcon)({
  width: ICON.NAVBAR_ITEM,
  height: ICON.NAVBAR_ITEM,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": { width: "100%", height: "100%" },
});

interface ItemRootProps {
  active: boolean;
  open?: boolean;
  onOpen?: () => void;
  item: any;
}

export function NavItemRoot({ item, open = false, active, onOpen }: ItemRootProps) {
  const { title, path, icon, info, children } = item;
  const { t } = useTranslation();

  const renderContent = (
    <>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle disableTypography primary={t(title)} />

      <>
        {info && info}
        {children && <ArrowIcon open={open} />}
      </>
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeRoot={active}>
        {renderContent}
      </ListItemStyle>
    );
  }

  return (
    <ListItemStyle component={RouterLink} to={path} activeRoot={active}>
      {renderContent}
    </ListItemStyle>
  );
}

interface ItemSubProps {
  active: boolean;
  open?: boolean;
  onOpen?: any;
  item: any;
}

export function NavItemSub({ item, open = false, active = false, onOpen }: ItemSubProps) {
  const { title, path, info, children } = item;
  const { t } = useTranslation();

  const renderContent = (
    <>
      <DotIcon active={active} />
      <ListItemText disableTypography primary={t(title)} />
      {info && info}
      {children && <ArrowIcon open={open} />}
    </>
  );

  if (children) {
    return (
      <ListItemStyle onClick={onOpen} activeSub={active} subItem>
        {renderContent}
      </ListItemStyle>
    );
  }

  return (
    <ListItemStyle component={RouterLink} to={path} activeSub={active} subItem>
      {renderContent}
    </ListItemStyle>
  );
}

interface DotProps {
  active: boolean;
}

export function DotIcon({ active }: DotProps) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: "50%",
          bgcolor: "text.disabled",
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: "scale(2)",
            bgcolor: "primary.main",
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

interface ArrowProps {
  open: boolean;
}

export function ArrowIcon({ open }: ArrowProps) {
  return open ? <ExpandMore /> : <NavigateNext />;
}
