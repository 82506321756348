import { LazyMotion, domMax } from "framer-motion";

interface Props {
  children: React.ReactNode;
}

export default function MotionLazyContainer({ children }: Props) {
  return (
    <LazyMotion strict features={domMax}>
      {children}
    </LazyMotion>
  );
}
