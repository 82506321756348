import { Key, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { List, Collapse } from "@mui/material";
import { NavItemRoot, NavItemSub } from "./NavItem";

function getActive(path: any, pathname: string) {
  return path ? !!matchPath({ path, end: false }, pathname) : false;
}

interface ListRootProps {
  list: any;
}

export function NavListRoot({ list }: ListRootProps) {
  const { pathname } = useLocation();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemRoot item={list} active={active} open={open} onOpen={() => setOpen(!open)} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {(list.children || []).map((item: { title: Key | null | undefined }) => (
              <NavListSub key={item.title} list={item} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemRoot item={list} active={active} />;
}

interface ListSubProps {
  list: any;
}

function NavListSub({ list }: ListSubProps) {
  const { pathname } = useLocation();
  const active = getActive(list.path, pathname);
  const [open, setOpen] = useState(active);
  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub item={list} onOpen={() => setOpen(!open)} open={open} active={active} />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub key={item.title} item={item} active={getActive(item.path, pathname)} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={active} />;
}
