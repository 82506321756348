export default {
  black: "#000000",
  black01: "rgba(0, 0, 0, 0.1)",
  blueJeans: "#54A9FF",
  brilliantAzure: "#2091F9",
  buttonBlue: "#2394E5",
  ceruleanBlue: "#3654C7",
  chineseSilver: "#CCCCCC",
  chineseWhite: "#E0E0E0",
  dodgerBlue: "#2091F9",
  ghostWhite: "#F9F9F9",
  fashionSkyBlue: "#1799E7",
  frenchSkyBlue: "#6CB4FF",
  maastrichtBlue: "#111632",
  nickel: "#7F7F7F",
  oldSilver: "#808080",
  platinum: "#E8E8E8",
  spanishYellow: "#FBB809",
  yankeesBlue: "#181F47",
  white: "#FFFFFF",
  white01: "rgba(255, 255, 255, 0.1)",
  white02: "rgba(255, 255, 255, 0.2)",
  zapposBlue: "#0076BD",
};
