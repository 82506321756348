import { useMemo } from "react";
import { ThemeProvider as MuiThemeProvider, createTheme, StyledEngineProvider } from "@mui/material/styles";
import componentsOverride from "./overrides/Overrides";
import palette from "./palette";
import shadows, { customShadows } from "./shadows";
import typography from "./typography";
import CssBaseline from "@mui/material/CssBaseline";
import breakpoints from "./breakpoints";
import useSettings from "../hooks/useSettings";

interface Props {
  children: React.ReactNode;
}

export default function ThemeProvider({ children }: Props) {
  const { themeMode } = useSettings();
  const isLight = themeMode === "light";

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight],
  );

  const theme = createTheme(themeOptions as any);
  theme.components = componentsOverride(theme) as any;

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}
