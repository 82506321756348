import { Box, Link, Stack, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet-async";
import useResponsive from "../../hooks/useResponsive";
import LoginForm from "./LoginForm";
import { Link as RouterLink } from "react-router-dom";
import City from "../../assets/images/heros/lamp.jpg";
import LogoName from "../../components/LogoName";
import Colors from "../../styles/Colors";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled("div")(() => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  flexGrow: 1,
  backgroundSize: "cover",
  backgroundImage: `url(${City})`,
  backgroundPosition: "center",
}));

const ContentStyle = styled("div")(() => ({
  maxWidth: 600,
  width: "100%",
  margin: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  minHeight: "100vh",
}));

function Login() {
  const { t } = useTranslation();
  const mdUp = useResponsive("up", "md");

  return (
    <>
      <Helmet>
        <title>{`${t("login")} | LSPDD`}</title>
      </Helmet>

      <RootStyle>
        {mdUp && <SectionStyle />}

        <ContentStyle>
          <Stack spacing={3}>
            <Box display="flex" justifyContent="center">
              <LogoName color={Colors.blueJeans} left={Colors.blueJeans} right={Colors.ceruleanBlue} logoSize="1.3em" fontSize="1.5rem" />
            </Box>

            <Typography variant="body2" textAlign="center">
              {t("no_account")} {""}
              <Link component={RouterLink} to="/contact-us" variant="subtitle2" underline="hover">
                {t("contact_us")}
              </Link>
            </Typography>

            <LoginForm />
          </Stack>
        </ContentStyle>
      </RootStyle>
    </>
  );
}

export default Login;
