import * as Yup from "yup";
import { useState } from "react";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../components/hook-form/FormProvider";
import RHFTextField from "../../components/hook-form/RHFTextField";
import ChangePasswordForm from "./ChangePasswordForm";

export default function LoginForm() {
  const { t } = useTranslation();
  const { login }: any = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(t("required.email")),
    password: Yup.string().required(t("required.password")),
  });

  const defaultValues = {
    email: "",
    password: "",
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      await login(data.email, data.password);
    } catch (error: any) {
      if (error.message === "Password reset is required") {
        setChangePassword(true);
      } else {
        reset();
        setError("root", { ...error, message: t("error.email_password") });
      }
    }
  };

  return !changePassword ? (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {errors.root && <Alert severity="error">{errors.root.message}</Alert>}

        <RHFTextField name="email" label={t("email_address")} autoFocus />

        <RHFTextField
          name="password"
          label={t("password")}
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t("login")}
        </LoadingButton>
      </Stack>
    </FormProvider>
  ) : (
    <ChangePasswordForm email={watch("email")} password={watch("password")} />
  );
}
