function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/dashboard";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
};

export const PATH_PAGE = {
  database: "/database",
  about: "/about-us",
  contact: "/contact-us",
  comparator: "/comparator",
  page404: "/404",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, "/general"),
    archive: path(ROOTS_DASHBOARD, "/archive"),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    newUser: path(ROOTS_DASHBOARD, "/user/new"),
  },
  model: {
    root: path(ROOTS_DASHBOARD, "/model"),
    edit: (id) => path(ROOTS_DASHBOARD, `/model/${id}/edit`),
  },
  lamp: {
    root: path(ROOTS_DASHBOARD, "/lamp"),
    edit: (id) => path(ROOTS_DASHBOARD, `/lamp/${id}/edit`),
  },
  capture: {
    root: path(ROOTS_DASHBOARD, "/capture"),
  },
  content: {
    root: path(ROOTS_DASHBOARD, "/content"),
    edit: (name) => path(ROOTS_DASHBOARD, `/content/${name}/edit`),
  },
};
