import { Link as RouterLink, Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import LogoName from "../components/LogoName";
import Colors from "../styles/Colors";

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

export default function SimpleLayout() {
  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <LogoName color={Colors.blueJeans} left={Colors.blueJeans} right={Colors.ceruleanBlue} logoSize="1.3em" fontSize="1.5rem" />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
