import { Container, Alert, AlertTitle } from "@mui/material";
import useAuth from "../hooks/useAuth";
import { useTranslation } from "react-i18next";

interface Props {
  accessibleRoles: string;
  children: React.ReactNode;
}

const useCurrentRole = () => {
  const { user }: any = useAuth();
  const role = user.roles.name;

  return role;
};

export default function RoleBasedGuard({ accessibleRoles, children }: Props) {
  const { t } = useTranslation();
  const currentRole = useCurrentRole();

  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>{t("permission_denied")}</AlertTitle>
          {t("no_permission")}
        </Alert>
      </Container>
    );
  }

  return <>{children}</>;
}
