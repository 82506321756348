import { useTranslation } from "react-i18next";
import { enUS, frFR } from "@mui/material/locale";

const LANGS = [
  {
    label: "English",
    value: "en",
    systemValue: enUS,
  },
  {
    label: "Français",
    value: "fr",
    systemValue: frFR,
  },
];

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem("i18nextLng");
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1];

  const handleChangeLanguage = (newlang: any) => {
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
