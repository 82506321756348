import { styled } from "@mui/material/styles";
import { List, Box, ListSubheader } from "@mui/material";
import { NavListRoot } from "./NavList";
import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";

export const ListSubheaderStyle = styled((props: any) => <ListSubheader disableSticky disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

interface Props {
  navConfig: Array<any>;
}

export default function NavSection({ navConfig, ...other }: Props) {
  const { t } = useTranslation();
  const { user }: any = useAuth();

  return (
    <Box {...other}>
      {navConfig.map(
        (group) =>
          (user.roles.name == group.role || !group.role) && (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              <ListSubheaderStyle>{t(group.subheader)}</ListSubheaderStyle>

              {group.items.map((list: any) => (
                <NavListRoot key={list.title} list={list} />
              ))}
            </List>
          ),
      )}
    </Box>
  );
}
