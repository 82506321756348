import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Box, styled } from "@mui/material";
import DashboardHeader from "./DashboardHeader";
import NavbarVertical from "./navbar/NavbarVertical";
import { HEADER, NAVBAR } from "../../config";

const MainStyle: any = styled("main")(({ theme }: any) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 3, 6, 3),
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create("margin-left", {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: { lg: "flex" },
        minHeight: { lg: 1 },
      }}
    >
      <DashboardHeader onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
