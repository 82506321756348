import { useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Divider, Typography, Stack, MenuItem, IconButton, Popover } from "@mui/material";
import useIsMountedRef from "../hooks/useIsMountedRef";
import useAuth from "../hooks/useAuth";
import { alpha } from "@mui/material/styles";
import { Person } from "@mui/icons-material";
import { SvgGradient } from "../styles/Styles";
import { useTranslation } from "react-i18next";

export default function AccountPopover() {
  const { t } = useTranslation();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { user, logout }: any = useAuth();
  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/auth/login", { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const MENU_OPTIONS = [
    {
      label: t("home"),
      linkTo: "/",
    },
    {
      label: t("dashboard.title"),
      linkTo: "/dashboard/general",
    },
  ];

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
            },
          }),
        }}
      >
        <SvgGradient />
        <Person sx={{ fill: "url(#linearColors)" }} />
      </IconButton>

      <Popover
        disableScrollLock
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {t("sign_out")}
        </MenuItem>
      </Popover>
    </>
  );
}
