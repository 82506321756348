import Avatar from "./Avatar";
import Button from "./Button";
import Card from "./Card";
import Checkbox from "./Checkbox";
import CssBaseline from "./CssBaseline";
import Drawer from "./Drawer";
import List from "./List";
import LoadingButton from "./LoadingButton";
import Menu from "./Menu";
import Slider from "./Slider";
import Tabs from "./Tabs";
import Typography from "./Typography";

export default function Overrides(theme: any) {
  return Object.assign(
    Avatar(theme),
    Button(theme),
    Card(theme),
    Checkbox(theme),
    CssBaseline(),
    Drawer(theme),
    List(theme),
    LoadingButton,
    Menu(theme),
    Slider(theme),
    Tabs(theme),
    Typography(theme),
  );
}
