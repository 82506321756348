import { styled } from "@mui/material/styles";
import { AppBar, Box, Stack, Toolbar } from "@mui/material";
import useOffSetTop from "../../hooks/useOffSetTop";
import AccountPopover from "../../components/AccountPopover";
import LanguagePopover from "../../components/LanguagePopover";
import cssStyles from "../../utils/cssStyles";
import useResponsive from "../../hooks/useResponsive";
import Colors from "../../styles/Colors";
import LogoName from "../../components/LogoName";
import IconButtonAnimate from "../../components/animate/IconButtonAnimate";
import { Menu } from "@mui/icons-material";

const RootStyle: any = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== "isOffset" && prop !== "verticalLayout",
})(({ isOffset, verticalLayout, theme }: any) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: 64,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: 92,
    width: `calc(100% - ${280 + 1}px)`,
    ...(isOffset && {
      height: 92 - 32,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: 92 - 32,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

interface HeaderProps {
  onOpenSidebar?: () => void;
  verticalLayout?: boolean;
}

export default function DashboardHeader({ onOpenSidebar, verticalLayout = false }: HeaderProps) {
  const isOffset = useOffSetTop(92) && !verticalLayout;
  const isDesktop = useResponsive("up", "lg");

  return (
    <RootStyle isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: "100% !important",
          px: { lg: 5 },
        }}
      >
        {isDesktop && verticalLayout && <LogoName color={Colors.frenchSkyBlue} left={Colors.blueJeans} right={Colors.ceruleanBlue} logoSize="1.3em" fontSize="1.5rem" />}

        {!isDesktop && (
          <IconButtonAnimate color="primary" onClick={onOpenSidebar}>
            <Menu />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
