import { m } from "framer-motion";
import { forwardRef } from "react";
import { Box, IconButton } from "@mui/material";

interface ButtonProps {
  children: React.ReactNode;
  color?: "inherit" | "default" | "primary" | "secondary" | "info" | "success" | "warning" | "error";
  size?: "small" | "medium" | "large";
}

const IconButtonAnimate: any = forwardRef(({ children, size = "medium", ...other }: ButtonProps, ref: any) => (
  <AnimateWrap size={size}>
    <IconButton size={size} ref={ref} {...other}>
      {children}
    </IconButton>
  </AnimateWrap>
));

export default IconButtonAnimate;

const varSmall = {
  hover: { scale: 1.1 },
  tap: { scale: 0.95 },
};

const varMedium = {
  hover: { scale: 1.09 },
  tap: { scale: 0.97 },
};

const varLarge = {
  hover: { scale: 1.08 },
  tap: { scale: 0.99 },
};

interface AnimateProps {
  children: React.ReactNode;
  size: "small" | "medium" | "large";
}

function AnimateWrap({ size, children }: AnimateProps) {
  const isSmall = size === "small";
  const isLarge = size === "large";

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={(isSmall && varSmall) || (isLarge && varLarge) || varMedium}
      sx={{
        display: "inline-flex",
      }}
    >
      {children}
    </Box>
  );
}
