import { SvgIcon, SvgIconProps } from "@mui/material";

function Logo(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg fill={props.fill} width={props.width} height={props.height} enableBackground="new 0 0 360 360" version="1.1" viewBox="0 0 360 360" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
        <path d="m182.31 119.05c1.11 32.02 26.82 57.72 58.84 58.84l2.87 0.1 67.24-16.4-1.21-9.22-83.12 15.28 119.41-51.29-3.56-8.6-120.7 48.17 102.06-80.45-5.67-7.38-104.12 77.76 58.07-77.87-7.38-5.67-60.76 75.81 48.16-120.7-8.6-3.56-51.29 119.41 15.28-106.08-9.23-1.21-16.4 90.21 0.11 2.85z" />
        <path d="m118.8 177.89c32.02-1.11 57.72-26.82 58.84-58.84l0.1-2.88-16.41-113.17-9.23 1.21 15.28 129.05-41.45-96.44-8.6 3.56 38.32 97.73-80.43-102.05-7.38 5.67 77.76 104.12-104.13-77.76-5.67 7.38 102.06 80.45-84.6-38.32-3.56 8.6 83.31 41.45-129.05-15.29-1.21 9.22 113.18 16.4 2.87-0.09z" />
        <path d="m177.63 241.4c-1.11-32.02-26.82-57.72-58.84-58.84l-2.88-0.1-113.16 16.4 1.21 9.22 129.05-15.28-96.44 38.17 3.56 8.6 97.73-35.04-102.05 80.45 5.67 7.38 104.12-77.76-61.36 84.44 7.38 5.66 64.04-82.38-48.16 120.7 8.6 3.56 51.29-119.4-12 109.36 9.23 1.21 13.12-93.48-0.11-2.87z" />
        <path d="m241.15 182.56c-32.02 1.11-57.72 26.82-58.84 58.84l-0.1 2.87 16.4 113.18 9.23-1.21-15.28-129.05 51.29 119.4 8.6-3.56-48.16-120.7 60.76 72.53 7.38-5.66-58.07-74.59 104.12 77.76 5.67-7.38-102.06-80.45 94.45 35.04 3.56-8.6-93.15-38.17 129.05 15.28 1.21-9.22-113.17-16.4-2.89 0.09z" />
      </svg>
    </SvgIcon>
  );
}

export default Logo;
