import { useState, useEffect } from "react";
import { NavLink as RouterLink, useLocation } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import { List, Drawer, ListItemText, ListItemButton, Box } from "@mui/material";
import { NAVBAR } from "../../config";
import Scrollbar from "../../components/Scrollbar";
import IconButtonAnimate from "../../components/animate/IconButtonAnimate";
import { Menu } from "@mui/icons-material";
import LogoName from "../../components/LogoName";
import Colors from "../../styles/Colors";
import { useTranslation } from "react-i18next";

const ListItemStyle: any = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body1,
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  color: theme.palette.text.secondary,
}));

const navConfig: any = [
  { title: "database", path: "/database" },
  { title: "about", path: "/about-us" },
  { title: "contact_us", path: "/contact-us" },
];

interface MenuProps {
  isOffset: boolean;
  isDark: boolean;
}

export default function MenuMobile({ isOffset, isDark }: MenuProps) {
  const { pathname } = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
  }, [pathname]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleDrawerOpen}
        sx={{
          ml: 1,
          ...(isDark && { color: "common.white" }),
          ...(isOffset && { color: "text.primary" }),
        }}
      >
        <Menu />
      </IconButtonAnimate>

      <Drawer open={drawerOpen} onClose={handleDrawerClose} ModalProps={{ keepMounted: true }} PaperProps={{ sx: { pb: 5, width: 260 } }}>
        <Scrollbar>
          <Box mx={2.5} my={3}>
            <LogoName color={Colors.blueJeans} left={Colors.blueJeans} right={Colors.ceruleanBlue} logoSize="1.3em" fontSize="1.5rem" />
          </Box>

          <List disablePadding>
            {navConfig.map((link) => (
              <MenuMobileItem key={link.title} item={link} />
            ))}
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}

interface ItemProps {
  item: {
    path: string;
    title: string;
  };
}

function MenuMobileItem({ item }: ItemProps) {
  const { title, path } = item;
  const { t } = useTranslation();

  return (
    <ListItemStyle
      to={path}
      component={RouterLink}
      end={path === "/"}
      sx={{
        "&.active": {
          color: "primary.main",
          fontWeight: "fontWeightMedium",
          bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      }}
    >
      <ListItemText disableTypography primary={t(title)} />
    </ListItemStyle>
  );
}
