import { useFormContext, Controller } from "react-hook-form";
import { TextField } from "@mui/material";

interface Props {
  name: string;
  label: string;
  multiline?: boolean;
  rows?: number;
  size?: "small" | "medium";
  type?: string;
  InputProps?: any;
  autoFocus?: boolean;
  sx?: any;
}

export default function RHFTextField({ name, label, multiline, rows, size, type, InputProps, autoFocus, sx, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          error={!!error}
          label={label}
          multiline={multiline}
          rows={rows}
          size={size}
          type={type}
          InputProps={InputProps}
          sx={sx}
          helperText={error?.message}
          autoFocus={autoFocus}
          {...other}
        />
      )}
    />
  );
}
