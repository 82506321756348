import { SxProps } from "@mui/material";
import Colors from "./Colors";

export const SvgGradient = () => (
  <svg width={0} height={0}>
    <linearGradient id="linearColors" x1={0} y1={1} x2={1} y2={1}>
      <stop offset={0} stopColor={Colors.blueJeans} />
      <stop offset={1} stopColor={Colors.ceruleanBlue} />
    </linearGradient>
  </svg>
);

export const link: SxProps = {
  color: Colors.fashionSkyBlue,
  fontWeight: 500,
  ":hover": {
    color: Colors.zapposBlue,
  },
};

export const slider: SxProps = {
  color: Colors.brilliantAzure,
  mt: "2.5rem",
  "& .MuiSlider-thumb": {
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.platinum}`,
  },
  "& .MuiSlider-valueLabel": {
    fontWeight: 700,
    backgroundColor: Colors.ghostWhite,
    border: `1px solid ${Colors.platinum}`,
    borderRadius: "4px",
    color: Colors.nickel,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
    },
  },
};
