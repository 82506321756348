import * as Yup from "yup";
import { useState } from "react";
import { Alert, IconButton, InputAdornment, Stack } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../components/hook-form/FormProvider";
import RHFTextField from "../../components/hook-form/RHFTextField";
import axios from "../../utils/axios";
import PasswordStrengthBar from "react-password-strength-bar";
import useAuth from "../../hooks/useAuth";

interface Props {
  email: string;
  password: string;
}

export default function ChangePasswordForm({ email, password }: Props) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordScore, setPasswordScore] = useState<number>(0);
  const { login }: any = useAuth();

  const ChangePasswordSchema = Yup.object().shape({
    email: Yup.string().required(t("required.email")),
    oldPassword: Yup.string().required(t("required.old_password")),
    newPassword: Yup.string()
      .required(t("required.new_password"))
      .notOneOf([Yup.ref("oldPassword")], t("required.different_password")),
    confirmNewPassword: Yup.string()
      .required(t("required.confirmation"))
      .oneOf([Yup.ref("newPassword")], t("required.same_password")),
  });

  const defaultValues = {
    email: email || "",
    oldPassword: password || "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const methods = useForm({
    resolver: yupResolver(ChangePasswordSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      if (passwordScore < 3) {
        setError("root", { message: t("error.password_not_strong") });
      } else {
        /* eslint-disable-next-line */
        await axios.post("/user/resetOwnPassword", { email: data.email, old_password: data.oldPassword, new_password: data.confirmNewPassword });
        await login(data.email, data.confirmNewPassword);
      }
    } catch (error: any) {
      reset();
      setError("root", { ...error, message: t("error.general") });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {errors.root ? <Alert severity="error">{errors.root.message}</Alert> : <Alert severity="info">{t("change_password")}</Alert>}

        <RHFTextField name="email" label={t("email_address")} />

        <RHFTextField name="oldPassword" type="password" label={t("dashboard.old_password")} />

        <Stack>
          <RHFTextField
            name="newPassword"
            type={showPassword ? "text" : "password"}
            label={t("dashboard.new_password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {watch("newPassword") && (
            <PasswordStrengthBar
              password={watch("newPassword")}
              shortScoreWord={t("level.too_short")}
              scoreWords={[t("level.weak"), t("level.weak"), t("level.good"), t("level.strong"), t("level.very_strong")]}
              onChangeScore={setPasswordScore}
            />
          )}
        </Stack>

        <RHFTextField name="confirmNewPassword" type="password" label={t("dashboard.confirm_new_password")} />

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          {t("login")}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
