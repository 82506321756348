import ThemeProvider from "./theme/ThemeProvider";
import ThemeLocalization from "./components/ThemeLocalization";
import Router from "./routes/Routes";
import MotionLazyContainer from "./components/animate/MotionLazyContainer";
import ScrollToTop from "./components/ScrollToTop";
import ChartStyle from "./pages/lamp/ChartStyle";

function App() {
  return (
    <ThemeProvider>
      <ThemeLocalization>
        <MotionLazyContainer>
          <ChartStyle />
          <ScrollToTop />
          <Router />
        </MotionLazyContainer>
      </ThemeLocalization>
    </ThemeProvider>
  );
}

export default App;
