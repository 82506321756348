import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
import useAuth from "../../../hooks/useAuth";

const RootStyle = styled("div")(({ theme }: any) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_8],
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

export default function NavbarAccount() {
  const { user }: any = useAuth();

  return (
    <Link underline="none" color="inherit" component={RouterLink} to="/dashboard/general">
      <RootStyle>
        <Box
          sx={{
            transition: (theme) =>
              theme.transitions.create("width", {
                duration: theme.transitions.duration.shorter,
              }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
